.page {
  @include base-wrap--space();
  width:auto;
  @include mq(sp) {
    padding: 25px 0 20px;
  }
  &__detail {
    padding: 70px 0 50px;
    @include mq(sp) {
      padding: 35px 0 0;
    }
  }
  .error-message {
    color: #f40000;
    font-size:1.6rem;
  }
  .alert-message {
    border: 2px solid #f40000;
    color: #F40000;
    padding: 10px 20px;
    font-weight: bold;
  }
  .mail-description {
    margin-bottom:60px;
  }
  form {
    th {
      font-weight: bold;
      width:30%;
    }
    .submit {
      margin-top:60px;
      #BtnMessageBack {
        background-color: #ccc;
        font-size:2rem;
        font-weight: normal;
      }
    }
  }
}
.index-year li{
  float: left;
  margin-left: 20px;
}
#AboutGuide .bge-contents img{
  border: 1px solid #ddd;
}
#CreatorIndex .dl-head__copy{
  padding-top:0!important;
  margin-top:-20px!important;
}