.case {
  padding-bottom: 40px;
  @include mq(sp) {
    padding-bottom: 20px;
  }
}
.case-head {
  @include base-wrap--space();
  padding: 65px 0 60px;
  font-size: 14px;
  text-align: center;
  @include mq(sp) {
    padding: 40px 0 30px;
  }
  a {
    text-decoration: underline;
  }
}

.case-category {
  @include base-wrap();
  padding-bottom: 70px;
  @include mq(sp) {
    padding-bottom: 35px;
  }
  ul {
    text-align: center;
    font-size: 0;
    li {
      display: inline-block;
      margin: 0 3px;
      font-size: 12px;
      @include mq(sp) {
        @include fz_vw(11);
        margin: 0 2px 4px;
      }
      a {
        display: block;
        padding: 6px 10px;
        border-radius: 40px;
        background: #F1F1F1;
        color: $black;
        @include mq(sp) {
          padding: 3px 9px;
        }
        &:hover {
          background: $main;
          color: #fff;
        }
      }
      &.this {
        a {
          background: $main;
          color: #fff;
        }
      }
    }
  }
}

.case-list {
  background: $light-gray;
  .inner {
    @include base-wrap();
    padding: 80px 0 10px;
    @include mq(sp) {
      padding: 40px 0 20px;
    }
  }
  ul {
    @include flex(wrap);
    li {
      width: 32%;
      margin-bottom: 70px;
      background: #fff;
      @include mq(sp) {
        width: 80%;
        margin: 0 auto 20px;
        padding: 2% 2% 20px 2%;
        background: #fff;
      }
      .li-eyecatch {
        overflow: hidden;
        img {
          transition: .5s;
        }
      }
      .li-text {
        position: relative;
        padding: 20px 5%;
        font-size: 13px;
        @include mq(sp) {
          padding: 0 3%;
        }
      }
      a {
        position: relative;
        display: block;
        color: $black;
        &::after {
          position: absolute;
          top: 0;
          left: 0;
          content: "";
          display: block;
          width: 100%;
          height: 0;
          background: rgba(0,0,0,.2);
          transition: .2s;
        }
        &:hover {
          &::after {
            height: 100%;
          }
          .li-eyecatch {
            img {
              transform: scale(1.1,1.1);
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.case-detail {
  padding: 40px 0;
  @include mq(sp) {
    padding: 20px 0;
  }
  &__url {
    margin-top: 40px;
    background: #F5D13F;
    text-align: center;
    @include mq(sp) {
      margin-top: 20px;
    }
    a {
      display: block;
      padding: 25px 0;
      color: $black;
      font-size: 18px;
      @include mq(sp) {
        @include fz_vw(13);
      }
      &:hover {
        text-decoration: underline;
      }
      span {
        padding: 0 45px;
        background: url(../img/top/icn_demo.svg) right center no-repeat;
        background-size: 16px 16px;
        @include mq(sp) {
          padding: 0 25px;
        }
      }
    }
  }
  .inner {
    @include base-wrap--space();
    @include clearfix();
    padding: 65px 0;
    @include mq(sp) {
      padding: 30px 0;
    }
  }
  .de-eyecatch {
    float: left;
    width: 466px;
    @include mq(sp) {
      float: none;
      width: auto;
      margin-bottom: 20px;
    }
  }
  .de-info {
    float: right;
    width: 450px;
    @include mq(sp) {
      float: none;
      width: auto;
    }
    a {
      // color: $black;
      background: url(../img/top/icn_demo_sub.svg) right center no-repeat;
      padding-right: 20px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.back_link {
  text-align: center;
  a {
    color: $black;
  }
}

