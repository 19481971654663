.bge_body {
  width: 860px;
  padding: 30px 0;
  font-size: 18px;
  letter-spacing: 2px;
  line-height: 2;
  margin: 0 auto;
  @include mq(sp) {
    width: auto;
    margin: 0 auto;
  }
  small{
    font-size: 13px;
    color:#555;
  }
  a {
    color: #33bad4;
    text-decoration: underline;
    &:hover {
      color: #6bddf2;
      text-decoration: none;
    }
  }
  p {
    margin-bottom:20px;
  }
  .navi {
    &:after {
      font-family: 'Font Awesome 5 Free';
      content: '\f35d';
      font-size: 0.9em;
      margin: 0 6px;
      font-weight: 900;
    }
  }
  ul {
    list-style:  none;
    margin:  0;
    padding: 0;
    li {
      line-height: 1.6em;
      margin-top: 12px;
      margin-bottom: 12px;
      padding-left: 8px;
      &:before {
        content:  "";
        width:  10px;
        height:  10px;
        display:  inline-block;
        background-color: #000;
        border-radius:  50%;
        position:  relative;
        top: -1px;
        margin-right: 5px;
      }
    }
    ul {
      margin-left: 20px;
    }
  }
  h1 {
    margin: 50px 0 20px;
    font-size: 26px;
    @include mq(sp) {
      margin: 25px 0 10px;
    }
  }
  h2 {
    margin: 50px 0 20px;
    padding-left: 20px;
    border-left: 4px solid $main;
    font-size: 24px;
    @include mq(sp) {
      margin: 25px 0 10px;
    }
  }
  h3 {
    margin: 50px 0 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #D3D3D4;
    font-size: 23px;
    @include mq(sp) {
      margin: 25px 0 10px;
    }
  }
  h4 {
    margin: 50px 0 20px;
    font-size: 20px;
    @include mq(sp) {
      margin: 25px 0 10px;
    }
  }
  h5 {
    margin: 50px 0 20px;
    @include mq(sp) {
      margin: 25px 0 10px;
    }
  }
  table {
    td {
      padding:15px;
      border-bottom:1px dotted #CCC;
    }
  }
}
blockquote {
  background-color: #efefef;
  padding: 15px 20px;
  border-radius: 10px;
}