@keyframes zoom {
  50% {
    transform: scale(1.05);
  }
}
@keyframes horizontal {
    0% { transform:translateX( -3px); }
  100% { transform:translateX(  0px); }
}
@keyframes vertical {
    0% { transform:translateY(-20px); }
  100% { transform:translateY(  0px); }
}
@keyframes slowloop {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes leftSlideIn {
  0% {
    left: -100%;
  }
  100% {
    left: 0;
  }
}

@-webkit-keyframes scroll {
  0% {bottom: 70px;opacity: 1;}
  50% {opacity:1;}
  100% {bottom: 30px;opacity:0;}
}
@keyframes scroll {
  0% {bottom: 70px;opacity: 1;}
  50% {opacity:1;}
  100% {bottom: 30px;opacity:0;}
}