.partner-head {
  @include base-wrap--space();
  padding: 65px 0 280px;
  background: url(../img/partner/img_map.png) top 10px right 30px no-repeat;
  background-size: 590px auto;
  text-align: center;
  @include mq(sp) {
    padding: 40px 0 160px;
    background-size: 65vw auto;
    background-position: top 80px right 10px;
  }
}

.partner-area {
  @include base-wrap--space();
  margin-bottom: 220px;
  @include mq(sp) {
    margin-bottom: 80px;
  }
  &__list {
    @include flex();
    position: relative;
    border-bottom: 1px solid #D7D5D5;
    text-align: center;
    .area-cate {
      width: 120px;
      @include mq(sp) {
        width: auto;
      }
      & > span {
        display: block;
        height: 42px;
        border-left: 1px solid #D7D5D5;
        border-right: 1px solid #D7D5D5;
        border-top: 1px solid #D7D5D5;
        border-radius: 6px 6px 0 0;
        color: $black;
        line-height: 42px;
        cursor: pointer;
        transition: .4s;
        @include mq(sp) {
          padding: 10px;
          border-radius: 0;
          height: auto;
          line-height: 1.2;
        }
        &:hover {
          border-color: $main;
          background: $main;
          color: #fff;
        }
      }
      ul {
        position: absolute;
        left: 0;
        top: 42px;
        display: none;
        width: 1000px;
        padding: 30px;
        border: 1px solid #D7D5D5;
        border-top: 4px solid #D7D5D5;
        @include mq(sp) {
          position: static;
          width: auto;
          padding: 15px;
          border-top-width: 1px;
          border-bottom: none;
          background: rgba(95,177,49,.1);
        }
        li {
          display: inline-block;
          a {
            display: inline-block;
            padding: 0 15px;
            color: $black;
            @include mq(sp) {
              padding: 5px 10px;
            }
            &:hover {
              color: $gray;
            }
          }
        }
      }
      &:first-child {
        ul {
          display: block;
        }
      }
      &.this {
        & > span {
          border-color: $main;
          background: $main;
          color: #fff;
        }
      }
    }
  }
}

.partner-official {
  @include base-wrap--space();
  padding: 75px 0 110px;
  @include mq(sp) {
    padding: 40px 0 50px;
  }
  &__box {
    @include flex();
    .image {
      position: relative;
      width: 512px;
      @include mq(sp) {
        width: auto;
        margin-bottom: 20px;
        margin-right: 4%;
        margin-left: 4%;
      }
      .icn_number {
        position: absolute;
        top: -22px;
        right: -18px;
        width: 88px;
        height: 88px;
        padding-top: 14px;
        background: url(../img/partner/bg_coin.png) 0 0 no-repeat;
        background-size: 88px 88px;
        color: #fff;
        text-align: center;
        line-height: 1.2;
        .ttl {
          font-size: 1.2rem;
        }
        .num {
          font-family: $font-jose;
          font-size: 3.6rem;
        }
      }
    }
    .info {
      width: 430px;
      @include mq(sp) {
        width: auto;
      }
      .info_list {
        margin-bottom: 40px;
        font-size: 1.4rem;
        @include mq(sp) {
          @include fz_vw(13);
          margin-bottom: 20px;
        }
        dt {
          clear: both;
          float: left;
          margin-bottom: 20px;
          @include mq(sp) {
            margin-bottom: 10px;
            line-height: 1.4;
          }
        }
        dd {
          margin-left: 100px;
          margin-bottom: 20px;
          @include mq(sp) {
            margin-left: 80px;
            margin-bottom: 10px;
            line-height: 1.4;
          }
          &.name {
            color: $main;
            font-size: 2.4rem;
            transform: translateY(-10px);
            @include mq(sp) {
              @include fz_vw(16);
              transform: translateY(-3px);
            }
          }
        }
      }
      .info_member {
        font-size: 1.4rem;
        @include mq(sp) {
          @include fz_vw(12);
          margin-bottom: 20px;
        }
        th {
          padding: 12px 20px;
          background: #D7D5D5;
          border: 1px solid #D7D5D5;
          font-weight: normal;
          text-align: left;
          @include mq(sp) {
            padding: 6px 10px;
          }
        }
        td {
          padding: 12px 20px;
          border: 1px solid #D7D5D5;
          @include mq(sp) {
            padding: 6px 10px;
            text-align: right;
          }
        }
      }
    }
    &:not(:last-child) {
      margin-bottom: 70px;
      padding-bottom: 70px;
      border-bottom: 4px solid #D7D5D5;
      @include mq(sp) {
        margin-bottom: 35px;
        padding-bottom: 35px;
      }
    }
  }
}

.partner-creative {
  @include base-wrap--space();
  padding: 60px 0 0;
  @include mq(sp) {
    padding: 30px 0 0;
  }
  .title--03 {
    margin: 0 0 42px;
    @include mq(sp) {
      margin: 0 0 24px;
    }
  }
  &__list {
    @include flex-sp(wrap);
    margin-bottom: 40px;
    justify-content: flex-start;
    li {
      width: 300px;
      margin-right: 50px;
      margin-bottom: 60px;
      text-align: center;
      @include mq(sp) {
        width: 48%;
        margin-right: 4%;
        margin-bottom: 30px;
      }
      &:nth-child(3n) {
        @include mq(pc) {
          margin-right: 0;
        }
      }
      &:nth-child(2n) {
        @include mq(sp) {
          margin-right: 0;
        }
      }
      .image {
        margin-bottom: 22px;
        @include mq(sp) {
          margin-bottom: 16px;
          text-align: center;
        }
      }
      .name {
        margin-bottom: 10px;
        font-size: 1.8rem;
        line-height: 1.3;
        @include mq(sp) {
          @include fz_vw(13);
          margin-bottom: 6px;
        }
      }
      .info {
        color: $black;
        font-size: 1.4rem;
        @include mq(sp) {
          @include fz_vw(11);
        }
        .work-num {
          margin-left: 10px;
        }
      }
    }
  }
}

.partner-main {
  padding: 70px 0 100px;
  @include mq(sp) {
    padding: 35px 0 50px;
  }
  &__title {
    background: rgba(95,177,49,.1);
    text-align: right;
    @include mq(sp) {
      text-align: center;
    }
    .inner {
      @include base-wrap--space();
      position: relative;
      padding: 75px 0 50px;
      @include mq(sp) {
        padding: 35px 0 25px;
      }
    }
    .type {
      position: absolute;
      top: -35px;
      right: 10px;
      width: 378px;
      height: 97px;
      padding-top: 24px;
      padding-left: 50px;
      background: url(../img/partner/bg_type.png) 0 0 no-repeat;
      background-size: 378px auto;
      color: #fff;
      font-size: 2rem;
      font-weight: bold;
      text-align: left;
      @include mq(sp) {
        @include fz_vw(12);
        top: -20px;
        right: 0;
        width: 200px;
        height: 55px;
        padding-top: 12px;
        padding-left: 16px;
        background-size: 200px auto;
      }
    }
    .name {
      display: inline-block;
      width: 400px;
      font-size: 2.6rem;
      line-height: 1.4;
      text-align: center;
      @include mq(sp) {
        @include fz_vw(18);
        width: auto;
      }
    }
  }

  .wrap {
    @include base-wrap--space();
    @include clearfix();
    position: relative;
  }

  &__logo {
    margin-left: 550px;
    padding: 80px 0 150px;
    text-align: center;
    @include mq(sp) {
      margin-left: 0;
      padding: 40px 0;
    }
  }
  &__web {
    position: absolute;
    top: -110px;
    left: 40px;
    width: 512px;
    @include mq(sp) {
      position: relative;
      top: auto;
      left: auto;
      width: auto;
      margin-left: 4%;
      margin-right: 4%;
      margin-bottom: 30px;
    }
    .icn_number {
      position: absolute;
      top: -22px;
      right: -18px;
      width: 88px;
      height: 88px;
      padding-top: 14px;
      background: url(../img/partner/bg_coin.png) 0 0 no-repeat;
      background-size: 88px 88px;
      color: #fff;
      text-align: center;
      line-height: 1.2;
      .ttl {
        font-size: 1.2rem;
      }
      .num {
        font-family: $font-jose;
        font-size: 3.6rem;
      }
    }
  }
  .button--01 {
    margin-bottom: 70px;
    text-align: center;
    @include mq(sp) {
      margin-bottom: 35px;
    }
    a {
      width: 390px;
      height: 90px;
      border-radius: 45px;
      line-height: 88px;
      @include mq(sp) {
        width: 80vw;
        height: 50px;
        line-height: 47px;
      }
      &::before {
        border-radius: 45px;
      }
      span {
        padding-left: 20px;
        background: url(../img/common/icn_demo.svg) 40px center no-repeat;
        background-size: 20px 20px;
        @include mq(sp) {
          background-size: 12px 12px;
          background-position: 50px center;
        }
      }
    }
  }

  .info {
    position: relative;
    float: left;
    width: 475px;
    padding-left: 45px;
    @include mq(sp) {
      float: none;
      width: auto;
      padding-left: 0;
    }
    .info_list {
      margin-bottom: 40px;
      font-size: 1.4rem;
      @include mq(sp) {
        @include fz_vw(13);
        margin-bottom: 20px;
      }
      dt {
        clear: both;
        float: left;
        margin-bottom: 20px;
        @include mq(sp) {
          margin-bottom: 10px;
          line-height: 1.4;
        }
      }
      dd {
        margin-left: 100px;
        margin-bottom: 20px;
        @include mq(sp) {
          margin-left: 80px;
          margin-bottom: 10px;
          line-height: 1.4;
        }
        &.name {
          color: $main;
          font-size: 2.4rem;
          font-weight: bold;
          transform: translateY(-10px);
          @include mq(sp) {
            @include fz_vw(16);
            transform: translateY(-3px);
          }
        }
      }
    }
    .info_member {
      font-size: 1.4rem;
      @include mq(sp) {
        @include fz_vw(12);
        margin-bottom: 20px;
      }
      th {
        padding: 12px 20px;
        background: #D7D5D5;
        border: 1px solid #D7D5D5;
        font-weight: normal;
        text-align: left;
        @include mq(sp) {
          padding: 6px 10px;
        }
      }
      td {
        padding: 12px 20px;
        border: 1px solid #D7D5D5;
        @include mq(sp) {
          padding: 6px 10px;
          text-align: right;
        }
      }
      .hint {
        display: inline-block;
        width: 20px;
        height: 20px;
        background: #fff;
        border-radius: 50%;
        color: $main;
        font-size: 15px;
        font-weight: bold;
        text-align: center;
        line-height: 20px;
        transform: translate(12px,-4px);
        cursor: pointer;
        @include mq(sp) {
          width: 14px;
          height: 14px;
          line-height: 14px;
          font-size: 11px;
        }
      }
    }
    .hint_box {
      display: none;
      position: absolute;
      bottom: -75px;
      left: 360px;
      width: 480px;
      padding: 10px 20px;
      border: 1px solid $main;
      font-size: 1.1rem;
    }
  }
  .info_text {
    float: right;
    width: 400px;
    @include mq(sp) {
      float: none;
      width: auto;
    }
    p {
      font-size: 1.4rem;
      @include mq(sp) {
        @include fz_vw(13);
      }
    }
  }
}

.partner-works {
  margin-bottom: 40px;
  background: #F1F1F1;
  .inner {
    @include base-wrap--space();
    padding: 80px 0 0;
    @include mq(sp) {
      padding: 30px 0;
    }
  }
  ul {
    @include flex(wrap);
    li {
      width: 32%;
      margin-bottom: 70px;
      background: #fff;
      @include mq(sp) {
        width: 80%;
        margin: 0 auto 20px;
        padding: 2% 2% 20px 2%;
        background: #fff;
      }
      .li-eyecatch {
        overflow: hidden;
        img {
          transition: .5s;
        }
      }
      .li-text {
        position: relative;
        padding: 20px 5%;
        font-size: 1.3rem;
        @include mq(sp) {
          padding: 0 3%;
        }
      }
      a {
        position: relative;
        display: block;
        color: $black;
        &::after {
          position: absolute;
          top: 0;
          left: 0;
          content: "";
          display: block;
          width: 100%;
          height: 0;
          background: rgba(0,0,0,.2);
          transition: .2s;
        }
        &:hover {
          &::after {
            height: 100%;
          }
          .li-eyecatch {
            img {
              transform: scale(1.1,1.1);
              opacity: 1;
            }
          }
        }
      }
    }
  }
}












