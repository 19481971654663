.hero {
  .slider {
    img {
      transform-origin: center top;
      transform: scale(1.1);
      transition: 5s ease-out;
    }
  
    @include mq(sp_tab) {
      width: 100vw;
      height: calc(100vh - 26px);
      position: absolute;
      top: 0;
      left: 0;
      margin: 0 auto;
      text-align: center;
      img {
        object-fit: cover;
        width: 100%;
        height: calc(100vh - 26px);
        margin: 0 auto;
        text-align: center;
      }
    }
  }
  
  .slider .slick-active img {
    transform: scale(1.0);
  }
  
  // slick slide first image animation
  .slider .slick-first-active img {
    animation-duration: 5s;
    animation-timing-function: ease-out;
    animation-name: slickActive1;
  }
  @include clearfix();
  &__mv {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: -1;
    @include mq(sp) {
      bottom: 0;
    }
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.4);
    }
  }
  &__body {
    padding: 240px 0 100px;
    text-align: center;
    z-index: 0;
    @include mq(sp) {
      padding: 100px 0;
    }
    &__inner {
      @include base-wrap();
      position: relative;
    }
    &__logo {
      margin-bottom: 24px;
      @include mq(sp) {
        margin-bottom: 20px;
      }
      img {
        width: 290px;
        height: 52px;
        @include mq(sp) {
          width: 145px;
          height: 26px;
        }
      }
    }
    &__text {
      margin-bottom: 55px;
      color: #fff;
      font-family: $font-noto;
      font-size: 28px;
      font-weight: bold;
      @include mq(sp) {
        @include fz_vw(14);
      }
    }
    &__link {
      &__item {
        display: inline-block;
        @include mq(sp) {
          display: block;
        }
        &:first-child {
          margin-right: 20px;
          @include mq(sp) {
            margin-right: 0;
            margin-bottom: 20px;
          }
          a {
            span {
              background: url(../img/top/icn_demo.svg) 40px center no-repeat;
              background-size: 16px 16px;
              @include mq(sp) {
                background-position: 30px center;
                background-size: 12px 12px;
              }
            }
          }
        }
        &:last-child {
          a {
            span {
              background: url(../img/top/icn_download.svg) 40px center no-repeat;
              background-size: 16px 16px;
              @include mq(sp) {
                background-position: 30px center;
                background-size: 12px 12px;
              }
            }
          }
        }
        a {
          position: relative;
          display: block;
          width: 290px;
          height: 68px;
          border: 1px solid #fff;
          border-radius: 34px;
          color: #fff;
          font-family: $font-noto;
          font-size: 16px;
          font-weight: bold;
          line-height: 63px;
          @include mq(sp) {
            @include fz_vw(12);
            display: inline-block;
            width: 60%;
            height: 48px;
            line-height: 48px;
          }
          span {
            position: relative;
            display: block;
            z-index: 3;
          }
          &:hover {
            border-color: $main;
          }
          &::before {
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 34px;
            -webkit-transform: scale(.5);
            transform: scale(.5);
            transition: all .3s;
            z-index: 1;
            @include mq(sp) {
              display: none;
            }
          }
          &:hover::before {
            background: $main;
            -webkit-transform: scale(1);
            transform: scale(1);
          }
        }
      }
    }
    &__scroll {
      position: relative;
      a {
        display: block;
        padding-top: 80px;
        color: #fff;
      }
      &::after {
        position: absolute;
        bottom: 30px;
        left: 50%;
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        border-right: 1px solid #fff;
        border-bottom: 1px solid #fff;
        transform: rotate(45deg) translateX(-50%);
        -webkit-animation: 1s ease .05s infinite scroll;
        animation: 1s ease .05s infinite scroll;
      }
    }
    &__banner {
      margin: 30px 20px 0px 20px;
    }
  }
}

.top-about {
  padding: 80px 0 100px;
  background: #fff url(../img/top/img_about.png) right bottom no-repeat;
  background-size: 46% auto;
  @include mq(sp) {
    padding: 40px 0 350px;
    background-size: 100% auto;
  }
  &__inner {
    @include base-wrap--space();
  }
  &__title {
    margin-bottom: 30px;
    padding-top: 70px;
    background: url(../img/common/logo_icon.svg) top center no-repeat;
    background-size: 65px 56px;
    font-family: $font-noto;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    @include mq(sp) {
      @include fz_vw(20);
      margin-bottom: 15px;
      padding-top: 30px;
      background-size: 30px 30px;
    }
  }
  &__text {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    @include mq(sp) {
      @include fz_vw(12);
      text-align: left;
    }
    p {
      margin-bottom: 30px;
      @include mq(sp) {
        margin-bottom: 15px;
      }
    }
  }
  &__list {
    padding: 105px 0 40px 30px;
    @include mq(sp) {
      padding: 20px 0 30px;
    }
    &__item {
      margin-bottom: 9px;
      padding-left: 40px;
      background: url(../img/top/icn_check.svg) 0 0 no-repeat;
      background-size: 24px 17px;
      @include mq(sp) {
        margin-bottom: 5px;
        padding-left: 20px;
        background-size: 12px 10px;
        background-position: 0 4px;
        line-height: 1.5;
      }
    }
  }
  .button--01 {
    margin-left: 55px;
    @include mq(sp) {
      margin-left: 0;
      text-align: center;
    }
    a {
      span {
        background: url(../img/top/icn_demo.svg) 50px center no-repeat;
        background-size: 16px 16px;
      }
    }
  }
}

.top-case {
  padding: 90px 0 80px;
  background: $light-gray;
  @include mq(sp) {
    padding: 40px 0 50px;
  }
  &__inner {
    @include base-wrap--space();
  }
  &__post {
    padding: 60px 0;
    font-size: 13px;
    @include mq(sp) {
      padding: 30px 0 10px;
    }
    ul {
      @include flex();
      flex-wrap: wrap;
      li {
        width: $golden-width;
        background: #fff;
        margin-bottom:20px;
        @include mq(sp) {
          width: 100%;
          margin: 0 0 20px;
          padding: 2% 2% 20px 2%;
          background: #fff;
        }
        .li-eyecatch {
          overflow: hidden;
          height: $golden-height;
          @include mq(sp) {
            margin-bottom: 8px;
            text-align: center;
          }
          img {
            width: $golden-width;
            height: auto;
            transition: .5s;
          }
        }
        .li-text {
          position: relative;
          padding: 20px 5%;
          @include mq(sp) {
            padding: 0 3%;
          }
        }
        a {
          position: relative;
          display: block;
          color: $black;
          &::after {
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            display: block;
            width: 100%;
            height: 0;
            background: rgba(0,0,0,.2);
            transition: .2s;
          }
          &:hover {
            &::after {
              height: 100%;
            }
            .li-eyecatch {
              img {
                transform: scale(1.1,1.1);
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

.top-feature {
  @include base-wrap--space();
  padding: 90px 0 50px;
  @include mq(sp) {
    padding: 45px 0;
  }
  &__list {
    @include flex();
    padding: 70px 0 55px;
    @include mq(sp) {
      padding: 35px 0 0;
    }
    &__item {
      width: 32%;
      @include mq(sp) {
        width: 100%;
        margin-bottom: 30px;
      }
      .li-image {
        margin-bottom: 30px;
        text-align: center;
        @include mq(sp) {
          margin-bottom: 15px;
        }
        img {
          width: 117px;
          height: 117px;
          @include mq(sp) {
            width: 60px;
            height: 60px;
          }
        }
      }
      .li-title {
        margin-bottom: 20px;
        color: $main;
        font-family: $font-noto;
        font-size: 22px;
        font-weight: bold;
        line-height: 1.1;
        text-align: center;
        @include mq(sp) {
          @include fz_vw(18);
        }
      }
      .li-copy {
        margin-bottom: 25px;
        font-size: 15px;
        font-weight: bold;
        @include mq(sp) {
          @include fz_vw(13);
          margin-bottom: 10px;
        }
      }
      .li-text {
        font-size: 14px;
        line-height: 1.8;
        @include mq(sp) {
          @include fz_vw(12);
        }
      }
    }
  }
  .button--01 {
    text-align: center;
  }
}

.top-howto {
  @include base-wrap--space();
  padding: 45px 0;
  margin-bottom: 140px;
  margin-top: 140px;
  @include mq(sp) {
    padding: 45px 0 20px;
  }
  &__list {
    @include flex();
    margin-top: 20px;
    padding: 80px 0 0;
    background: url(../img/top/bg_howto.svg) top center no-repeat;
    background-size: 684px 72px;
    @include mq(sp) {
      padding: 0;
      background: none;
    }
    &__item {
      width: 32%;
      @include mq(sp) {
        width: auto;
        margin-bottom: 30px;
      }
      .li-image {
        margin-bottom: 10px;
        text-align: center;
        img {
          width: 100px;
          @include mq(sp) {
            width: 80px;
          }
        }
      }
      .li-title {
        margin-bottom: 30px;
        font-size: 23px;
        line-height: 1.5;
        text-align: center;
        @include mq(sp) {
          @include fz_vw(18);
          line-height: 1.2;
        }
      }
      .button--01 {
        text-align: center;
        a {
          width: 296px;
          @include mq(sp) {
            width: 65%;
          }
        }
      }
      &:nth-child(1) {
        .button--01 {
          a {
            border-color: $orange;
            background: $orange;
            &:hover {
              color: $orange;
            }
          }
        }
      }
      &:nth-child(2) {
        .button--01 {
          a {
            border-color: $ocian;
            background: $ocian;
            &:hover {
              color: $ocian;
            }
          }
        }
      }
      &:nth-child(3) {
        .button--01 {
          a {
            border-color: $pink;
            background: $pink;
            &:hover {
              color: $pink;
            }
          }
        }
      }
    }
  }
}

.top-bnr {
  margin-bottom: 80px;
  text-align: center;
  @include mq(sp) {
    margin-bottom: 40px;
    padding: 0 4%;
  }
}

.top-news {
  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  
  .slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
  }
  
  .slick-list:focus {
    outline: none;
  }
  
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }
  
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  
  .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
  }
  
  .slick-track:before, .slick-track:after {
    content: "";
    display: table;
  }
  
  .slick-track:after {
    clear: both;
  }
  
  .slick-loading .slick-track {
    visibility: hidden;
  }
  
  .slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none;
  }
  
  [dir="rtl"] .slick-slide {
    float: right;
  }
  
  .slick-slide img {
    display: block;
  }
  
  .slick-slide.slick-loading img {
    display: none;
  }
  
  .slick-slide.dragging img {
    pointer-events: none;
  }
  
  .slick-initialized .slick-slide {
    display: block;
  }
  
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
  
  .slick-arrow.slick-hidden {
    display: none;
  }

  &__post {
    margin-bottom:20px;
    display:none;
    li {
      width:300px;
      a {
        color:#fff;
        &:hover {
          img {
            transform: scale(1.1,1.1);
          }
          .li-date {
            color:#fff!important;
          }
        }
      }
      .li-eyecatch {
        background-color:#000;
        margin-bottom: 24px;
        overflow: hidden;
        height: 120px;
        position:relative;
        border-top:1px solid #fff;
        border-left:1px solid #fff;
        @include mq(sp) {
          height: 110px;
          margin-bottom: 12px;
        }
        img {
          width: 100%;
          max-width: none;
          transition: .5s;
          opacity:0.5;
        }
       .li-category {
          position: absolute;
          right: 0;
          bottom:0;
          display: inline-block;
          min-width: 100px;
          padding: 6px 10px;
          color: #fff;
          font-size: 11px;
          text-align: center;
          vertical-align: middle;
          @include mq(sp) {
            @include fz_vw(10);
            padding: 0 8px;
          }
        }
      }
   
      .li-text {
        position:relative;
        margin-top:-165px;
        padding: 20px;
        z-index:1;
        width:100%;
        height:140px;
        @include mq(sp) {
          margin-top:-130px;
        }
        .li-meta {
          .li-date {
            color: #CCC;
            font-size: 14px;
            vertical-align: middle;
            @include mq(sp) {
              @include fz_vw(10);
            }
          }
        }
        .li-title {
          font-size: 14px;
          font-weight: bold;
          @include mq(sp) {
            @include fz_vw(10);
          }
          a {
            padding-bottom: 5px;
            border-bottom: 0 solid $gray;
            color: $black;
            transition: .2s;
            &:hover {
              padding-bottom: 3px;
              border-width: 1px;
            }
          }
        }
      }
    }
  }
}

.top-info {
  @include base-wrap--space();
  padding: 15px 0 40px;
  h2 {
    background: none;
    font-weight: normal;
    padding-bottom: 0;
    padding-top: 0;
  }
  &__post {
    padding: 50px 80px 25px;
    @include mq(sp) {
      padding: 20px 0;
    }
    ul {
      li {
        margin-bottom: 15px;
        .li-date {
          float: left;
          display: block;
          padding-top: 3px;
          font-size: 14px;
          @include mq(sp) {
            @include fz_vw(12);
            float: none;
          }
        }
        .li-title {
          display: block;
          margin-left: 100px;
          @include mq(sp) {
            margin-left: 0;
          }
          a {
            color: $main;
            font-size: 16px;
            text-decoration: underline;
            @include mq(sp) {
              @include fz_vw(12);
            }
            &:hover {
              opacity: .6;
            }
          }
        }
      }
    }
  }
}

.top-for {
  @include base-wrap--space;
  padding: 50px 0;
  @include mq(sp) {
    padding: 40px 0;
  }
  &__intro {
    padding: 45px 0;
    text-align: center;
    @include mq(sp) {
      padding: 20px 0;
    }
    &__text01 {
      margin-bottom: 10px;
      font-size: 32px;
      font-weight: bold;
      @include mq(sp) {
        @include fz_vw(16);
      }
    }
    &__text02 {
      font-size: 18px;
      @include mq(sp) {
        @include fz_vw(12);
        text-align: left;
      }
    }
  }
  &__list {
    @include clearfix();
    padding: 30px 0;
    @include mq(sp) {
      padding: 15px 0;
    }
    &__item {
      float: left;
      width: 32%;
      margin-right: 2%;
        a {
          color: #000;
        }
      @include mq(sp) {
        width: auto;
        margin-right: 0;
        margin-bottom: 20px;
      }
      &:last-child {
        margin-right: 0;
      }
      &__image {
        margin-bottom: 16px;
        a {
          position: relative;
          display: block;
          &::before {
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            display: block;
            width: 100%;
            height: 0;
            background: rgba(0,0,0,.4);
            transition: .2s;
          }
          &::after {
            position: absolute;
            top: 50%;
            left: 50%;
            content: "";
            display: block;
            width: 32px;
            height: 32px;
            background: url(../img/common/icn_link_w.svg) 0 0 no-repeat;
            background-size: 32px 32px;
            transform: translate(-50%,-50%);
            opacity: 0;
            transition: 1s;
          }
          &:hover {
            &::before {
              height: 100%;
            }
            &::after {
              opacity: 1;
            }
            img {
              opacity: 1 !important;
            }
          }
        }
      }
      &__title {
        margin-bottom: 12px;
        color: $main;
        font-size: 21px;
        text-align: center;
        @include mq(sp) {
          @include fz_vw(13);
          margin-bottom: 5px;
          text-align: left;
        }
        a {
          color: $main;
        }
        span {
          padding-left: 30px;
          background: url(../img/common/icn_link.svg) 0 3px no-repeat;
          background-size: 16px 16px;
          @include mq(sp) {
            padding-left: 20px;
            background-size: 12px 12px;
            background-position: 0 1px;
          }
        }
      }
      &__text {
        font-size: 14px;
        line-height: 1.8;
        @include mq(sp) {
          @include fz_vw(12);
        }
      }
    }
  }
}
