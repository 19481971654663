.title--01 {
  font-family: $font-jose;
  font-size: 4.8rem;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  @include mq(sp) {
    @include fz_vw(30);
  }
  span {
    display: block;
    font-family: $font-base;
    font-size: 1.8rem;
    font-weight: normal;
    @include mq(sp) {
      @include fz_vw(12);
    }
  }
}
.title--02 {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #f5f5f5;
  font-size: 2.4rem;
  font-weight: bold;
  text-align: center;
  @include mq(sp) {
    @include fz_vw(18);
  }
}
.title--03 {
  margin-bottom: 25px;
  font-size: 2.4rem;
  text-align: center;
  @include mq(sp) {
    @include fz_vw(18);
    margin-bottom: 12px;
  }
}
.title--04 {
  margin-bottom: 25px;
  color: $main;
  font-family: $font-jose;
  font-size: 1.6rem;
  @include mq(sp) {
    @include fz_vw(14);
    margin-bottom: 12px;
  }
}
.title--05 {
  padding: 20px 0;
  background: $main;
  color: #fff;
  font-family: $font-jose;
  font-size: 2.4rem;
  font-weight: normal;
  text-align: center;
  @include mq(sp) {
    @include fz_vw(18);
    padding: 10px 0;
  }
}
.title--06 {
  margin-bottom: 10px;
  font-size: 3.2rem;
  text-align: center;
  @include mq(sp) {
    @include fz_vw(20);
  }
}
