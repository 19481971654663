.press-release-footer {
  padding:20px;
  background-color: #efefef;
  margin-top:80px;
  font-size:1.6rem;
  border-radius: 10px;
  h3 {
    font-size: 2rem;
    border:none;
    margin:0!important;
  }
  dl {
    font-size:1.6rem;
    margin-top:40px;
    margin-bottom:40px;
    dt {
      font-weight: bold;
    }
    dd {
      margin-left:30px;
    }
  }
}