$black: #000;
$gray: #686C67;
$light-gray: #F1F1F1;
$main: #5FB131;
$sub: #002e73;
$ocian: #6CC4C4;
$pink: #EF8C8D;
$orange: #F8B700;

$base-lh: 1.7;
$base-fs: 16px;
$reset-fs: 12;

$mincho: "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "ＭＳ 明朝", serif;
$font-yu: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
$font-base: "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
$marugo: "ヒラギノ丸ゴ Pro W4","Hiragino Maru Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
$font-noto: 'Noto Sans JP', sans-serif;
$font-jose: 'Josefin Sans', sans-serif;

// 黄金比
$golden-ratio: 1.6180339887498948482;
$golden-width: 320px;
$golden-height: $golden-width / $golden-ratio;