.creator {
  padding-bottom: 40px;
  @include mq(sp) {
    padding-bottom: 20px;
  }
}
.creator-head {
  margin: 60px 0 100px;
  background: $main;
  @include mq(sp) {
    margin: 30px 0 40px;
  }
  &__inner {
    @include flex();
    @include base-wrap();
  }
  &__image {
    width: 70%;
    @include mq(sp) {
      width: auto;
    }
  }
  &__text {
    width: 50%;
    padding: 40px 0 0 40px;
    color: #fff;
    @include mq(sp) {
      width: auto;
      padding: 30px 5vw;
    }
    &__copy {
      margin-bottom: 15px;
      font-size: 23px;
      font-weight: bold;
      @include mq(sp) {
        @include fz_vw(16);
      }
    }
  }
}
.creator-partner, .creator-market {
  @include base-wrap--space();
  margin-bottom: 80px;
  @include mq(sp) {
    margin-bottom: 40px;
  }
  strong {
    color: #EB6100;
  }
  p {
    margin-bottom: 30px;
  }
}

.back_link {
  text-align: center;
  a {
    color: $black;
  }
}

.creator-link {
  padding: 50px 0;
  background: $light-gray;
  &__inner {
    @include flex(wrap);
    @include base-wrap--space();
    margin-bottom: 50px;
  }
  &__item {
    width: 49%;
    margin-bottom: 15px;
    @include mq(sp) {
      width: auto;
    }
    a {
      display: block;
      height: 100%;
      padding: 50px;
      background: #fff;
      color: $black;
      @include mq(sp) {
        padding: 30px;
      }
      &:hover {
        background: rgba(0,0,0,.1);
      }
    }
    &:nth-child(1) .icon {
      img {
        width: 76px;
        height: 73px;
      }
    }
    &:nth-child(2) .icon {
      img {
        width: 81px;
        height: 79px;
      }
    }
    &:nth-child(3) .icon {
      img {
        width: 81px;
        height: 62px;
      }
    }
    &:nth-child(4) .icon {
      img {
        width: 81px;
        height: 58px;
      }
    }
    .icon {
      margin-bottom: 30px;
      text-align: center;
    }
    &__title {
      margin-bottom: 20px;
      font-size: 24px;
      text-align: center;
      @include mq(sp) {
        @include fz_vw(18);
        margin-bottom: 10px;
      }
    }
    .button--05 {
      margin-top: 30px;
    }
  }
  .creator-bnr {
    text-align: center;
    @include mq(sp) {
      padding: 0 4%;
    }
  }
}

.creator-community {
  padding: 80px 0;
  background: url(../img/creator/bg_communituy.png) center no-repeat;
  background-size: cover;
  color: #fff;
  text-align: center;
  @include mq(sp) {
    padding: 50px 0;
  }
  &__title {
    margin-bottom: 30px;
    font-size: 24px;
    @include mq(sp) {
      @include fz_vw(18);
    }
  }
  &__text {
    margin-bottom: 30px;
  }
  .button--04 {
    a {
      border: 0;
    }
  }
}




