.table--01 {
  tr {
    border: 0;
    &:nth-child(odd) {
      background: #F4F4F3;
    }
  }
  th {
    padding: 20px 30px;
    font-weight: bold;
    @include mq(sp) {
      padding: 18px 0 0 15px;
    }
  }
  td {
    padding: 20px 30px;
    @include mq(sp) {
      padding: 10px 15px 15px;
    }
  }
}
.table--02 {
  font-size: 12px;
  @include mq(sp) {
    @include fz_vw(12);
  }
  th {
    width: 14.2%;
    border: 1px solid #B5B2B0;
    padding: 12px 10px;
    background: #F1F1F1;
    text-align: center;
    line-height: 1.25;
  }
  td {
    padding: 12px 10px;
    border: 1px solid #B5B2B0;
    line-height: 1.25;
    text-align: center;
    a {
      color: $main;
      text-decoration: underline;
    }
  }
}
.table--03 {
  margin-bottom: 70px;
  font-size: 14px;
  @include mq(sp) {
    @include fz_vw(12);
    margin-bottom: 35px;
  }
  th {
    padding: 12px 0;
    text-align: left;
    @include mq(sp) {
      display: block;
      width: 100%;
      padding-bottom: 0;
    }
  }
  td {
    text-align: left;
    @include mq(sp) {
      display: block;
      width: 100%;
      padding-top: 0;
    }
  }
}

.table-form {
  tr {
    border-bottom: 1px solid #d2d2d2;
  }
  tr:last-child {
    border-bottom: 0;
  }
  th, td {
    padding: 10px 25px;
    @include mq(sp) {
      display: block;
      width: 100%;
    }
  }
  th {
    width: 230px;
    padding-right: 15px;
    font-weight: normal;
    text-align: left;
    @include mq(sp) {
      padding: 20px 0 0;
      padding-bottom: 0;
    }
  }
  td {
    padding-left: 0;
    @include mq(sp) {
      padding: 10px 0 20px;
    }
  }
}
.table-scroll {
  overflow: auto;
  white-space: nowrap;
  &::-webkit-scrollbar {
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #F1F1F1;
  }
  &::-webkit-scrollbar-thumb {
    background: #BCBCBC;
  }
}

@include mq(sp) {
  table.stripe {
    overflow: hidden;
  }
  table.stripe colgroup col{
    width: auto;
  }
  table.stripe .thead {
    display: none;
  }
  table.stripe tr {
    width: 100%;
    border: 1px solid #6ab440;

  }
  table.stripe td {
    display: block;
    text-align: right;
    width: 100%;
  }
  table.stripe td.thin {
    border-left: 2px solid #6ab440;
    border-bottom: 1px solid #6ab440;;
    width: 100%;
    font-weight: bold;
    text-align: center;
  }
  table.stripe tr.has-category td.thin:before {
    content: "分類";
    float: left;
    font-weight: lighter;
    opacity: 0.5;
    margin-right: 10px;
  }
  table.stripe tr.has-category td:nth-child(2):before {
    content: "機能";
    float: left;
    font-weight: lighter;
    opacity: 0.5;
    margin-right: 10px;
  }
  table.stripe tr.has-category td:nth-child(3):before {
    content: "説明";
    float: left;
    font-weight: lighter;
    opacity: 0.5;
    margin-right: 10px;
  }
  table.stripe td:first-child:before {
    content: "機能";
    float: left;
    font-weight: lighter;
    opacity: 0.5;
    margin-right: 10px;
  }
  table.stripe td:last-child:before {
    content: "説明";
    float: left;
    font-weight: lighter;
    opacity: 0.5;
    margin-right: 10px;
  }
}