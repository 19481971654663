@charset "utf-8";

$w1366:1366px;
$w1200:1200px;
$w1000:1000px;
$w320:320px;
$largeRange: 1024px;
$mediumRange: 960px;
$smallRange: 760px;
@mixin mq($media) {
	@if $media == sp {
		@media screen and (max-width: #{$smallRange}) {
			@content;
		}
	}
	@if $media == tab {
		@media screen and (max-width: #{$mediumRange}) and (min-width: #{$smallRange}) {
			@content;
		}
	}
	@if $media == pc {
		@media screen and (min-width: #{$smallRange + 1}) {
			@content;
		}
	}
	@if $media == wpc {
		@media screen and (min-width: #{$mediumRange + 1}) {
			@content;
		}
	}
	@if $media == w1000 {
		@media screen and (min-width: #{$w1000}) {
			@content;
		}
	}
	@if $media == w1200 {
		@media screen and (max-width: #{$w1200}) {
			@content;
		}
	}
	@if $media == w320 {
		@media screen and (max-width: #{$w320}) {
			@content;
		}
	}
	@if $media == wide {
		@media screen and (min-width: #{$w1366}) {
			@content;
		}
	}
}
