.button--01 {
  a {
    position: relative;
    display: inline-block;
    width: 352px;
    height: 82px;
    border: 1px solid $main;
    border-radius: 41px;
    background: $main;
    color: #fff;
    font-family: $font-noto;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 80px;
    text-align: center;
    @include mq(sp) {
      @include fz_vw(12);
      display: inline-block;
      width: 90%;
      height: 60px;
      line-height: 60px;
    }
    span {
      position: relative;
      display: block;
      z-index: 3;
    }
    &:hover {
      color: $main;
    }
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 41px;
      -webkit-transform: scale(.5);
      transform: scale(.5);
      transition: all .3s;
      z-index: 1;
      @include mq(sp) {
        display: none;
      }
    }
    &:hover::before {
      background: #fff;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}

.button--02 {
  text-align: center;
  a {
    display: inline-block;
    padding: 0 35px;
    background: url(../img/common/icn_arrow.svg) 0 10px no-repeat;
    background-size: 23px 9px;
    color: $black;
    font-family: $font-jose;
    font-size: 2.1rem;
    font-weight: bold;
    transition: .2s;
    @include mq(sp) {
      @include fz_vw(12);
      padding: 0 20px;
      background-size: 14px 5px;
      background-position: 0 5px;
    }
    &:hover {
      background-position: 100% 10px;
      @include mq(sp) {
        background-position: 100% 5px;
      }
    }
  }
}

.button--03 {
  text-align: right;
  @include mq(sp) {
    text-align: center;
  }
  a {
    display: inline-block;
    padding: 0 35px;
    background: url(../img/common/icn_arrow.svg) 0 5px no-repeat;
    background-size: 22px 8px;
    color: $black;
    font-family: $font-jose;
    font-size: 1.4rem;
    font-weight: bold;
    transition: .2s;
    @include mq(sp) {
      @include fz_vw(12);
      padding: 0 20px;
      background-size: 14px 5px;
      background-position: 0 5px;
    }
    &:hover {
      background-position: 100% 5px;
    }
  }
}
.wrapper--top {
  .button--03 {
    text-align: center;
  }
}
