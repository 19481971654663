.required {
  display: inline-block;
  width: 44px;
  border-radius: 4px;
  line-height: 25px;
  background: rgba(95,177,49,.9);
  color: #fff;
  font-size: 1.2rem;
  text-align: center;
  margin-right:10px;
  @include mq(sp) {
    @include fz_vw(12);
    line-height: 24px;
  }
}
.normal {
  display: inline-block;
  width: 44px;
  border-radius: 4px;
  line-height: 25px;
  background: #CCC;
  color: #fff;
  font-size: 1.2rem;
  text-align: center;
  margin-right:10px;
  @include mq(sp) {
    @include fz_vw(12);
    line-height: 24px;
  }
}
.any {
  float: right;
  display: inline-block;
  width: 50px;
  border-radius: 4px;
  line-height: 30px;
  background: #bfbfbf;
  color: #fff;
  font-size: 1.2rem;
  text-align: center;
  @include mq(sp) {
    @include fz_vw(12);
    line-height: 24px;
  }
}
.submit {
  text-align: center;
  button, input[type="submit"], input[type="reset"] {
    position: relative;
    width: 395px;
    border-radius: 6px;
    background: #ccc;
    color: #fff;
    line-height: 56px;
    cursor: pointer;
    @include mq(sp) {
      @include fz_vw(16);
      width: 100%;
    }
    &::before {
      position: absolute;
      top: calc(50% - 2px);
      right: 30px;
      content: "";
      display: block;
      width: 33px;
      height: 6px;
      background: url(../img/common/icn_arrow.svg) no-repeat;
      background-size: 33px 6px;
      transform: translate(0,-50%);
      transition: .2s;
      @include mq(sp) {
        top: 50%;
        width: 26px;
        height: 4px;
        background-size: 26px 4px;
      }
    }
    &:hover {
      &::before {
        @include mq(pc) {
          right: 10px;
        }
      }
    }
  }
  input[type="submit"] {
    background-color: #5fb131;
    font-weight: bold;
  }
  input[type="reset"] {
    font-size:2rem;
  }
}

/* reset */

button,
option,
textarea,
input[type="button"],
input[type="submit"],
input[type="number"],
input[type="email"],
input[type="tel"],
input[type="text"],
input[type="option"],
input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    border: none;
    outline: 0;
    margin: 0;
    background: #fff;
    box-sizing: border-box;
    font-size: 2rem;
    @include mq(sp) {
      @include fz_vw(12);
    }
}
select,
input[type="select"] {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    border: none;
    outline: 0;
    margin: 0;
    background: #fff;
    box-sizing: border-box;
    font-size: 2rem;
    @include mq(sp) {
      @include fz_vw(12);
    }
}
/* デフォルトのradio、checkboxは非表示 */

input[type=radio],
input[type=checkbox] {
    display: none;
    //margin-right:10px;
    //font-size:1.6rem;
}

/* for firefox */

select {
    text-indent: 0.01px;
    text-overflow: '';
    height:48px;
}




textarea,
input[type="number"],
input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    border-radius: 6px;
    margin-top:5px;
    margin-bottom:5px;
    @include mq(sp) {
      padding: 10px 8px;
    }
}
input.form-s {
  width: 80px;
  @include mq(sp) {
    width: 25%;
    margin-bottom: 5px;
  }
}
input.form-m {
  width: 420px;
  @include mq(sp) {
    width: 100%;
  }
}
input.form-l {
  width: 100%;
  @include mq(sp) {
    width: 100%;
  }
}
textarea {
    height: 120px; /* お好みの高さに */
    padding: 10px 10px;
}

/* フォーカス時の色変更 */

textarea:focus,
input[type="tel"]:focus,
input[type="email"]:focus,
input[type="text"]:focus {
    border: 2px solid rgba(95,177,49,.9);
}
/* for ie10 ie11 ie系のプルダウンの矢印を消す ie9は非対応 */
select::-ms-expand {
    display: none;
}

/* ラジオボタン */

input[type=radio].form-radio+label {
    position: relative;
    display: inline-block;
    font-size: 1.4rem;
    cursor: pointer;
    padding: 9px 5px 8px 28px;
    margin-right: 10px;
}

input[type=radio].form-radio+label::before,
input[type=radio].form-radio+label::after {
    position: absolute;
    display: block;
    content: '';
    top: 50%;
    left: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
}

input[type=radio].form-radio+label::before {
    width: 22px;
    height: 22px;
    margin-top: -12px;
    background: #FFF;
    border: 1px solid #ccc;
}

input[type=radio].form-radio+label::after {
    left: 6px;
    width: 10px;
    height: 10px;
    margin-top: -6px;
    background: #f4f4f4;
}

input[type=radio]:checked.form-radio+label::after {
    background: $main;
}

/* チェックボックス */

.form-checkbox label {
    position: relative;
    display: inline-block;
    font-size: 1.4rem;
    cursor: pointer;
    margin-right: 10px;
    padding: 6px 5px 8px 30px;
}

.form-checkbox label::before,
.form-checkbox label::after {
    position: absolute;
    display: block;
    content: '';
    top: 50%;
    left: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}

.form-checkbox label::before {
    width: 22px;
    height: 22px;
    margin-top: -12px;
    background: #FFF;
    border: 2px solid #ccc;
}

.form-checkbox input[type=checkbox]+label::after {
    width: 24px;
    height: 24px;
    top: 2px;
}

.form-checkbox input[type=checkbox]:checked+label::after {
    position: absolute;
    display: block;
    content: '';
    background: url(../img/contact/icn_check.svg) 0 0 no-repeat;
    background-size: 24px 24px;
    left: -1px;
    top: -1px;
}

.form-select {
    display: inline-block;
    position: relative;
    min-width: 300px;
    line-height: 48px;
    border: 1px solid #ccc;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    border-radius: 6px;
    overflow: hidden;
    padding-left:10px;
    padding-right:10px;
    margin-top:5px;
    margin-bottom:5px;
}

.form-select:after {
    position: absolute;
    display: block;
    content: '';
    width: 0;
    height: 0;
    /* ここで下向き矢印指定　今回はCSSで */
    border-top: 6px solid transparent;
    border-left: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #333;
    top: 50%;
    right: 10px;
    margin-top: -3px;
    pointer-events: none;/* クリック出来るよう */
}

.form-select select {
    min-width: 280px;
    height: 35px;
    font-size: 1.4rem;
    padding: 0 10px;
    border: none;
    position: relative;
    @include mq(sp) {
      @include fz_vw(12);
    }
}
span.mail-attention {
  display: block;
  margin-top: -1rem;
}


/* ie9対策 */

.form-select select:not(:target) {
    width: 120% \9;
}

/* Google Chrome, Safari, Opera 15+, Android, iOS */

::-webkit-input-placeholder {
    color: #ccc;
}

/* Firefox 18- */

:-moz-placeholder {
    color: #ccc;
    opacity: 1;
}

/* Firefox 19+ */

::-moz-placeholder {
    color: #ccc;
    opacity: 1;
}

/* IE 10+ */

:-ms-input-placeholder {
    color: #ccc;
}

#ContactIndex {
  table {
    th {
      text-align: left;
      @include mq(sp) {
        display: block;
        width: 100%;
      }
    }
    td {
      @include mq(sp) {
        display: block;
        width: 100%;
        padding-bottom: 30px;
      }
    }
  }
}
