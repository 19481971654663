.header {
  position: fixed;
  top: 40px;
  left: 0;
  width: 100%;
  height: 78px;
  z-index: 100;
  @include mq(sp) {
    top: 0;
    height: 50px;
    background: rgba(255,255,255,.8);
  }
  &__inner {
    @include clearfix();
    @include base-wrap();
    width: calc(1000px + 30px);
    position: relative;
    top: 0;
    transition: .2s;
    @include mq(sp) {
      width: auto;
    }
  }
  &__left {
    float: left;
    width: 796px;
    height: 78px;
    padding-left: 17px;
    border-radius: 5px;
    background: rgba(255,255,255,.9);
    @include mq(sp) {
      display: none;
    }
  }
  &__right {
    float: right;
    width: 222px;
    height: 78px;
    border-radius: 5px;
    background: rgba(95,177,49,.9);
    @include mq(sp) {
      display: none;
    }
  }
  &__logo {
    display: inline-block;
    width: 45px;
    vertical-align: middle;
    margin-right:23px;
    a {
      overflow: hidden;
      padding: 25px 23px 30px 23px;
    }
    @include mq(sp) {
      display: none;
    }
    img {
      width: 45px;
      height: 38px;
      @include mq(sp) {
        width: 124px;
        height: 60px;
      }
    }
    &--icon {
      position: fixed;
      top: -100px;
      left: 20px;
      opacity: 0;
      transition: .2s;
      @include mq(sp) {
        position: relative;
        top: 0;
        left: 0;
        z-index: 1000;
        display: block;
        padding: 17px 0 0 1px;
        opacity: 1;
        a {
          padding: 20px 23px 18px 23px;
        }
      }
      img {
        @include mq(sp) {
          width: 23px;
          height: 19px;
        }
      }
    }
  }
}
.gnavi {
  display: inline-block;
  padding-left: 20px;
  &__list {
    display: inline-block;
    @include mq(sp) {
      display: none;
    }
    &__item {
      position: relative;
      display: inline-block;
      padding: 20px 10px;
      a {
        position: relative;
        display: block;
        color: $black;
        font-size: 13px;
        font-weight: bold;
        line-height: 40px;
        &::before {
          position: absolute;
          left: 50%;
          bottom: 5px;
          content: "";
          display: block;
          width: 0;
          height: 3px;
          background: $main;
          transition: .2s;
        }
        &:hover::before {
          left: 0;
          width: 100%;
          transform: translateX(0);
        }
      }
    }
  }
  &__sub-list {
    position: absolute;
    visibility: hidden;
    top: 130px;
    left: 0;
    width: 200px;
    padding-top: 20px;
    transition: .5s;
    opacity: 0;
    &::before {
      position: absolute;
      left: 36px;
      top: 0;
      content: "";
      display: block;
      width: 6px;
      height: 6px;
      margin-left: -3px;
      border-top: 2px solid $main;
      border-right: 2px solid $main;
      transform: rotate(135deg);
    }
    &__item {
      margin-bottom: 1px;
      a {
        position: relative;
        display: block;
        background: rgba(95,177,49,.9);
        padding: 10px 20px;
        color: #fff;
        &::before {
          bottom: 0;
          background: #fff;
        }
        &::after {
          position: absolute;
          top: 50%;
          right: 20px;
          content: "";
          display: none;
          width: 4px;
          height: 4px;
          margin-top: -2px;
          border-top: 2px solid #fff;
          border-right: 2px solid #fff;
          transform: rotate(45deg);
        }
      }
      a[target=_blank] {
        padding-right: 30px;
        background: rgba(95,177,49,.9) url(../img/common/icn_target.svg) right 10px center no-repeat;
        background-size: 24px 24px;
      }
    }
  }
}
.gnavi__list__item:hover {
  &::before {
    display: block;
  }
  .gnavi__sub-list {
    top: 59px;
    visibility: visible;
    opacity: 1;
  }
}

.sub-navi {
  padding-left: 20px;
  &__list {
    padding: 22px 0;
    &__item {
      position: relative;
      display: inline-block;
      line-height: 1.2;
      text-align: center;
      &:first-child {
        padding-right: 20px;
        &::after {
          position: absolute;
          right: -5px;
          top: 50%;
          content: "／";
          color: #fff;
          transform: translateY(-50%);
        }
      }
      a {
        position: relative;
        display: inline-block;
        color: #fff;
        font-size: 12px;
        &::before {
          position: absolute;
          left: 50%;
          bottom: -10px;
          content: "";
          display: block;
          width: 0;
          height: 3px;
          background: #fff;
          transition: .2s;
        }
        &:hover::before {
          left: 0;
          width: 100%;
          transform: translateX(0);
        }
      }
    }
  }
}

.pc-menu {
  position: fixed;
  top: -100px;
  right: 20px;
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: $main;
  opacity: 0;
  transition: .2s;
  @include mq(sp) {
    display: none;
  }
  .panel-btn-icon {
    background: #fff;
  }
  .panel-btn-icon::before, .panel-btn-icon::after {
    background: #fff;
  }
  .panel-btn-icon::after {
    margin-top: 6px;
  }
  .panel-btn .close::before, .panel-btn .close::after {
    background: #fff;
  }
}


.changed .header__inner {
  top: -200px;
}
.online .header__inner {
  top: 0;
}
.changed .header__logo--icon {
  top: 40px;
  opacity: 1;
}
.online .header__logo--icon {
  opacity: 0;
}
.changed .pc-menu {
  top: 40px;
  opacity: 1;
}

.sp-navi {
  display: none;
  @include mq(sp) {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
    display: none;
    width: 100%;
    height: 100vh;
    //padding-top: 40px;
    padding-top: 15vw;
    background: rgba(0,0,0,.7);
    text-align: center;
  }
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    content: "";
    width: 100%;
    height: 50px;
    background: $black;
  }
  &__list {
    @include mq(sp) {
      margin-bottom:5vw;
    }
    &__item {
      a {
        position: relative;
        display: block;
        font-size: 13px;
        font-weight: bold;
        @include mq(sp) {
          @include fz_vw(12);
          //padding: 6px 0;
          padding: 2vw 0 1vw;
          color: #fff;
          font-family: $font-base;
        }
      }
    }
  }
  &__sub-list {
    width: 80%;
    margin: 0 auto;
    @include mq(sp) {
    }
    &__item {
      background: rgba(255,255,255,.7);
      &:not(:last-child) {
        border-bottom: 1px solid rgba(0,0,0,.4);
      }
      a {
        @include mq(sp) {
          @include fz_vw(11);
          padding: 5px 0;
          color: $black;
          font-weight: normal;
          font-size: 10px;
        }
      }
      a[target=_blank] {
        background: url(../img/common/icn_target_b.svg) right 10px center no-repeat;
        background-size: 16px 16px;
      }
    }
  }
}

.dl-bar {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 149;
  background: #413F41;
  color: #fff;
  @include mq(sp) {
    display: block;
    top: auto;
    bottom: 0;
    z-index: 90;
    background: $main;
  }
  &__count {
    @include base-wrap--space();
    font-family: $font-noto;
    font-weight: bold;
    text-align: right;
    @include mq(sp) {
      text-align: center;
    }
  }
  &__title {
    margin-right: 8px;
    font-size: 10px;
    vertical-align: middle;
    @include mq(sp) {
      @include fz_vw(10);
    }
  }
  &__unit {
    margin-left: 8px;
    margin-right: 16px;
    font-size: 10px;
    vertical-align: middle;
    @include mq(sp) {
      @include fz_vw(10);
    }
  }
  &__num {
    font-size: 18px;
    vertical-align: middle;
    @include mq(sp) {
      @include fz_vw(18);
    }
  }
}

.side-box {
  position: fixed;
  bottom: 50px;
  right: 0;
  @include mq(sp) {
    display: none;
  }
  .bnr-box {
    margin-bottom: 20px;
  }
}

.dl-box {
  width: 240px;
  padding: 20px 0;
  border-radius: 10px 0 0 10px;
  background: rgba(95,177,49,.9);
  width: 200px;
  color: #fff;
  &__logo {
    width: 133px;
    margin: 0 auto 6px;
  }
  &__count {
    padding-left: 20px;
    font-weight: bold;
    line-height: 1.3;
    dt {
      clear: both;
      float: left;
      padding-top: 6px;
      font-size: 10px;
    }
    dd {
      margin-left: 80px;
      font-size: 18px;
      .unit {
        font-size: 10px;
        vertical-align: middle;
      }
    }
  }
  &__button {
    margin: 10px 20px 0;
    a {
      display: inline-block;
      width: 100%;
      padding-left: 15px;
      border-radius: 50px;
      background: #fff url(../img/common/icn_download_g.svg) 20px center no-repeat;
      background-size: 12px 12px;
      font-size: 11px;
      font-weight: bold;
      line-height: 37px;
      text-align: center;
      &:hover {
        color: $main;
        background-position: 20px 18px;
      }
    }
  }
}