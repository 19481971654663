.dl-head {
  @include base-wrap--space();
  padding-bottom: 60px;
  @include mq(sp) {
    padding-bottom: 30px;
  }
  &__copy {
    margin-bottom: 50px;
    padding-top: 80px;
    color: #EC6519;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    @include mq(sp) {
      @include fz_vw(18);
      margin-bottom: 25px;
      padding-top: 40px;
    }
    &__sm {
      margin-bottom: 25px;
      padding-top: 25px;
      font-size: 15px;
      color: #EC6519;
      font-weight: bold;
      text-align: center;  
      white-space: nowrap;
    }
  }
  .button--01 {
    margin-bottom: 25px;
    text-align: center;
    a {
      width: 505px;
      height: 91px;
      padding-left: 20px;
      border-radius: 46px;
      font-size: 24px;
      line-height: 86px;
      @include mq(sp) {
        width: 80vw;
        height: 50px;
        font-size: 14px;
        line-height: 47px;
      }
      &::before {
        border-radius: 46px;
      }
      span {
        background: url(../img/common/icn_download.svg) 30px center no-repeat;
        background-size: 28px 28px;
        @include mq(sp) {
          padding-left: 20px;
          background-size: 16px 16px;
          background-position: 15px center;
        }
      }
    }
  }
  &__meta {
    width: 230px;
    margin: 0 auto;
    font-size: 12px;
  }
}

.dl-merumaga {
  background: rgba(132,192,36,.19);
  .inner {
    @include base-wrap--space();
    padding: 90px 0 70px;
    background: url(../img/download/img_get.png) 690px 25px no-repeat;
    background-size: 250px auto;
    @include mq(sp) {
      padding: 40px 0;
      background-position: right 10px;
      background-size: 120px auto;
    }
  }
  .title--03 {
    margin-bottom: 30px;
  }
  &__form {
    padding-right: 40px;
    text-align: center;
    @include mq(sp) {
      padding-right: 0;
    }
    input[type=text] {
      width: 330px;
      border: 0;
      border-radius: 0;
      @include mq(sp) {
        width: 70vw;
        height: 35px;
        line-height: 32px;
      }
    }
    input[type=submit] {
      height: 43px;
      line-height: 43px;
      padding: 0 20px;
      background: $main;
      color: #fff;
      @include mq(sp) {
        height: 35px;
        line-height: 32px;
      }
    }
  }
}

.dl-backno {
  @include base-wrap--space();
  padding: 70px 0 100px;
  @include mq(sp) {
    padding: 35px 0 50px;
  }
}






