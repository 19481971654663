.pagetop {
  opacity: 0;
}

.footer {
  background: #fff;
}

.friend-wrap {
  background: $light-gray;
}

.sponsor {
  position: relative;
  padding: 65px 0 30px;
  @include mq(sp) {
    padding: 30px 0 0;
  }
  &__title {
    position: absolute;
    top: 0;
    right: 50px;
    display: none;
    color: #fff;
    font-size: 6px;
    font-family: $font-jose;
    line-height: 1;
    opacity: .5;
  }
  &__inner {
    @include base-wrap--space();
    position: relative;
    z-index: 1;
  }
  &__list {
    @include clearfix();
    display: flex;
    @include mq(sp) {
      display: block;
    }
    margin-bottom: 2vw;
    &__item {
      float: left;
      margin-right: 2%;
      margin-bottom: 20px;
      @include mq(sp) {
        width: 49%;
      }
      &:nth-child(2n) {
        @include mq(sp) {
          margin-right: 0;
        }
      }
      &:nth-child(3n) {
        @include mq(pc) {
          margin-right: 0;
        }
      }
      &__title {
        margin-bottom: 25px;
        font-family: $font-jose;
        font-weight: 300;
        text-align: center;
        @include mq(sp) {
          @include fz_vw(10);
          margin-bottom: 8px;
        }
      }
      &__logo {
        img {
          width: 80%;
          margin: 5vw 2.5vw;
          transition: .5s;
        }
        a {
          display: block;
          overflow: hidden;
          &:hover {
            img {
              transform: scale(1.1,1.1);
            }
          }
        }
      }
      &__gold {
        width: 66%;
        @include mq(sp) {
          width: 100%;
        }
        margin-right: 1.5vw;
        ul {
          display: flex;
          li {
            background: white;
          }
        }
      }
      &__partner {
        width: 33%;
        text-align: center;
        div {
          background: white;
        }
        img {
          width: 74%;
        }
        @include mq(sp) {
          width: 100%;
          margin-top: 1.5vw;
          img {
            width: 32%;
          }
        }
      }
      &__bronze {
        width: 100%;
        div {
          text-align: center;
          background: white;
          a {
            width: 100%;
          }
          img {
            width: 15%;
            @include mq(sp) {
              width: 30%;
            }
            margin: 2.5vw 0px;
          }
        }
      }
    }
  }
}
.partner {
  position: relative;
  padding: 36px 0 50px;
  @include mq(sp) {
    padding: 10px 0;
  }
  &__title {
    position: absolute;
    top: 0;
    right: 50px;
    display: none;
    color: #fff;
    font-size: 6px;
    font-family: $font-jose;
    line-height: 1;
    opacity: .5;
  }
  &__inner {
    @include base-wrap--space();
    position: relative;
    z-index: 1;
  }
  &__subtitle {
    margin-bottom: 35px;
    font-family: $font-jose;
    font-weight: 300;
    text-align: center;
    @include mq(sp) {
      @include fz_vw(10);
      margin-bottom: 8px;
    }
  }
  &__list {
    @include clearfix();
    padding: 0 50px;
    @include mq(sp) {
      padding: 0 0 30px;
    }
    li {
      float: left;
      position: relative;
      width: 23%;
      margin-right: 2.66%;
      margin-bottom: 12px;
      padding-left: 10px;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.4;
      @include mq(sp) {
        @include fz_vw(10);
        width: 49%;
        margin-right: 2%;
        margin-bottom: 9px;
      }
      &:nth-child(4n) {
        @include mq(pc) {
          margin-right: 0;
        }
      }
      &:nth-child(4n+1) {
        @include mq(pc) {
          clear: both;
        }
      }
      &:nth-child(even) {
        @include mq(sp) {
          margin-right: 0;
        }
      }
      &:nth-child(2n+1) {
        @include mq(sp) {
          clear: both;
        }
      }
      &::before {
        position: absolute;
        top: 5px;
        left: 0;
        content: "";
        display: block;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: $black;
      }
      a {
        color: $black;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
.cooperate {
  @include base-wrap--space();
  padding-bottom: 40px;
  &__title {
    margin-bottom: 30px;
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    @include mq(sp) {
      @include fz_vw(12);
      margin-bottom: 15px;
    }
  }
  &__list {
    @include clearfix();
    li {
      float: left;
      width: 220px;
      margin-right: 40px;
      @include mq(sp) {
        width: 49%;
        margin-right: 2%;
        margin-bottom: 10px;
      }
      &:nth-child(4n) {
        @include mq(pc) {
          margin-right: 0;
        }
      }
      &:nth-child(even) {
        @include mq(sp) {
          margin-right: 0;
        }
      }
      &:nth-child(odd) {
        @include mq(sp) {
          clear: both;
        }
      }
      a {
        display: block;
        overflow: hidden;
        img {
          transition: .5s;
        }
        &:hover {
          img {
            transform: scale(1.1,1.1);
          }
        }
      }
      p {
        margin-top: 10px;
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        line-height: 1.2;
        @include mq(sp) {
          @include fz_vw(11);
        }
      }
    }
  }
}

.footer__buttons {
  @include base-wrap--space();
  padding: 56px 0;
  font-size: 0;
  text-align: center;
  @include mq(sp) {
    padding: 30px 0;
  }
  .button--01 {
    display: inline-block;
    padding: 0 15px;
    @include mq(sp) {
      width: 50%;
      padding: 0;
    }
    &:first-child {
      @include mq(sp) {
        padding-right: 5px;
      }
      a {
        span {
          background: url(../img/common/icn_demo.svg) 50px center no-repeat;
          background-size: 21px 21px;
          @include mq(sp) {
            padding-left: 10px;
            background-size: 12px 12px;
            background-position: 15px center;
          }
        }
        &:hover {
          span {
            background: url(../img/common/icn_demo_g.svg) 40px center no-repeat;
            background-size: 21px 21px;
            @include mq(sp) {
              background-size: 12px 12px;
              background-position: 15px center;
            }
          }
        }
      }
    }
    &:last-child {
      @include mq(sp) {
        padding-left: 5px;
      }
      a {
        span {
          background: url(../img/common/icn_download.svg) 40px center no-repeat;
          background-size: 28px 28px;
          @include mq(sp) {
            padding-left: 20px;
            background-size: 16px 16px;
            background-position: 15px center;
          }
        }
        &:hover {
          span {
            background: url(../img/common/icn_download_g.svg) 40px center no-repeat;
            background-size: 28px 28px;
            @include mq(sp) {
              background-size: 16px 16px;
              background-position: 15px center;
            }
          }
        }
      }
    }
    a {
      width: 389px;
      height: 92px;
      border-radius: 46px;
      line-height: 90px;
      @include mq(sp) {
        width: 100%;
        height: 50px;
        line-height: 50px;
      }
      &::before {
        border-radius: 46px;
      }
    }
  }
}

.footer__merumaga {
  background: $light-gray;
  &__inner {
    @include base-wrap--space();
    padding: 30px 0;
    text-align: center;
    background: url(../img/common/img_bessy.png) right bottom no-repeat;
    background-size: 98px 67px;
    @include mq(sp) {
      padding: 15px 0 55px;
      background-size: 70px auto;
    }
  }
  &__text {
    display: inline-block;
    margin-right: 20px;
    font-size: 16px;
    font-weight: bold;
    vertical-align: middle;
    @include mq(sp) {
      @include fz_vw(13);
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
  &__form {
    display: inline-block;
    vertical-align: middle;
    @include mq(sp) {
      display: block;
    }
    input[type=text] {
      width: 330px;
      height: 38px;
      padding: 0 10px;
      border: 0;
      border-radius: 0;
      font-size: 20px;
      box-sizing: border-box;
      @include mq(sp) {
        width: 80%;
        height: 32px;
        font-size: 16px;
      }
    }
    input[type=submit] {
      width: 80px;
      height: 38px;
      background: $main;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
      @include mq(sp) {
        width: 20%;
        height: 32px;
      }
    }
  }
}

.fnavi {
  @include base-wrap--space();
  padding: 0 0 50px;
  @include mq(sp) {
    padding: 0 0 30px;
  }
  &__links {
    @include clearfix();
    padding: 75px 0 0;
    @include mq(sp) {
      padding: 40px 0 0;
    }
    &__list {
      float: left;
      width: 24%;
      margin-right: 1.3333%;
      font-size: 12px;
      &:nth-child(1) {width:20%}
      &:nth-child(2) {width:20%}
      &:nth-child(3) {width:28%}
      &:nth-child(4) {width:28%}
      @include mq(sp) {
        @include fz_vw(11);
        width: 48% !important;
        margin-right: 4%;
      }
      &:last-child {
        @include mq(pc) {
          margin-right: 0;
        }
      }
      &:nth-child(even) {
        @include mq(sp) {
          margin-right: 0;
        }
      }
      a {
        color: $black;
        &:hover {
          color: $main;
        }
      }
      & > li {
        margin-bottom: 20px;
        @include mq(sp) {
          margin-bottom: 10px;
        }
        & > a {
          font-weight: bold;
        }
      }
      &__items {
        margin-left: 1em;
        a {
          display: inline-block;
          @include mq(sp) {
            line-height: 1.2;
            margin: 4px 0;
          }
          &[target=_blank] {
            &::after {
              content: "";
              display: inline-block;
              width: 16px;
              height: 16px;
              padding-right: 14px;
              background: url(../img/common/icn_target_g.svg) right 10px top 2px no-repeat;
              background-size: 16px 16px;
              transform: translateY(1.5px);
              @include mq(sp) {
                padding-right: 0;
                background-position: right top 5px;
                background-size: 12px 12px;
                transform: translateY(1px);
              }
            }
          }
        }
      }
    }
  }
}

.copyright {
  padding: 40px 0;
  background: #F4F4F3;
  color: #666;
  font-size: 14px;
  @include mq(sp) {
    padding: 20px 0;
  }
  &__inner {
    @include base-wrap--space();
  }
  p {
    color: $black;
    font-family: $font-noto;
    font-size: 16px;
    text-align: center;
    @include mq(sp) {
      @include fz_vw(10);
    }
  }
  .sns-link {
    margin-bottom: 10px;
    font-size: 0;
    text-align: center;
    li {
      display: inline-block;
      &:not(:last-child) {
        margin-right: 15px;
      }
      a {
        display: block;
        width: 29px;
        @include mq(sp) {
          width: 29px;
        }
        img {
          width: 29px;
          height: 29px;
          @include mq(sp) {
            width: 29px;
            height: 29px;
          }
        }
      }
    }
  }
}
