@include mq(pc) {
  .delighter.anime-left {
    transition: all .3s ease-out;
    transform: translateX(-10%);
    opacity: 0;
    &.started {
      transform: none;
      opacity: 1;
      &.ended {
      }
    }
  }
  .delighter.anime-right {
    transition: all .3s ease-out;
    transform: translateX(10%);
    opacity: 0;
    &.started {
      transform: none;
      opacity: 1;
      &.ended {
      }
    }
  }
  .delighter.anime01 {
    transition: all .3s linear;
    transform: translateY(100%);
    opacity: 1;
    &.started {
      transform: none;
      opacity: 1;
    }
  }
  .delighter.anime01-2 {
    transition: all .3s linear .3s;
    transform: translateY(120%);
    opacity: 1;
    &.started {
      transform: none;
      opacity: 1;
    }
  }
  .delighter.anime02 {
    transition: all 1s ease-out;
    transform: translateY(20%);
    opacity: 0;
    &.started {
      transform: none;
      opacity: 1;
      &.ended {
      }
    }
  }
  .delighter.anime02-2 {
    transition: all 1s ease-out .3s;
    transform: translateY(20%);
    opacity: 0;
    &.started {
      transform: none;
      opacity: 1;
      &.ended {
      }
    }
  }
  .delighter.anime02-3 {
    transition: all 1s ease-out .6s;
    transform: translateY(20%);
    opacity: 0;
    &.started {
      transform: none;
      opacity: 1;
      &.ended {
      }
    }
  }
  .delighter.anime03 {
    transition: all .6s ease-out;
    transform: translateY(30%);
    opacity: 0;
    &.started {
      transform: none;
      opacity: 1;
    }
  }
  .delighter.anime-rotate {
    transition: all .5s ease-out;
    transform: none;
    &.started {
      transform: rotate(-30deg);
      &.ended {
        transform: rotate(710deg);
      }
    }
  }
}