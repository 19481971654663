.sp-menu {
  display: none;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  @include mq(sp) {
    display: block;
    top: 7px;
    right: 7px;
    z-index: 1000;
    width: 36px;
    height: 36px;
  }
}
.panel-btn {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  text-decoration: none;
  text-align: center;
  @include box-sizing(border-box);
  @include mq(sp) {
    width: 36px;
    height: 36px;
  }
}
.panel-btn .close {
  background: transparent;
}
.panel-btn .close::before, .panel-btn .close::after {
  margin-top: -2px;
  background: $gray;
}
.panel-btn .close::before {
  -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
}
.panel-btn .close::after {
  -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
}
.panel-btn-icon {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 22px;
  height: 3px;
  border-radius: 3px;
  background: $black;
  -webkit-transition: .2s;
  transition: .2s;
  transform: translate(-50%,-50%);
  @include mq(sp) {
    width: 18px;
    height: 2px;
  }
}
.panel-btn-icon::before, .panel-btn-icon::after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 22px;
  height: 3px;
  border-radius: 3px;
  background: $black;
  -webkit-transition: .3s;
  transition: .3s;
  @include mq(sp) {
    width: 18px;
    height: 2px;
  }
}
.panel-btn-icon::before {
  margin-top: -10px;
  @include mq(sp) {
    margin-top: -7px;
  }
}
.panel-btn-icon::after {
  margin-top: 5px;
}
