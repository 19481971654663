/* 全共通 */
.wrapper {
  position: relative;
  overflow: hidden;
  min-width: 1200px;
  @include mq(sp) {
    min-width: auto;
  }
  &--top {
    .main {
      background: #fff;
    }
  }
  &--page {
    .main {
      padding-top: 155px;
      @include mq(sp) {
        padding-top: 50px;
      }
    }
  }
}
