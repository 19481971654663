.hosting {
  .dl-head {
    &__copy{
      margin-bottom:100px;
    }
  }
  .bge_body {
    margin-left:auto!important;
    margin-right:auto!important;
    h3 {
      font-size: 20px;
    }
    ul li {
      font-size: 16px;
    }
  }
}

.hosting-head {
  @include base-wrap();
  margin-bottom: 50px;
  padding: 40px 0;
  @include mq(sp) {
    margin-bottom: 0;
  }
  .copy-head {
    font-size: 18px;
    text-align: center;
    @include mq(sp) {
      @include fz_vw(14);
    }
  }
}

.hosting-partner {
  @include base-wrap();
  position: relative;
  margin-bottom: 60px;
  border: 8px solid #E1EED2;
  @include mq(sp) {
    margin-bottom: 30px;
    border-width: 4px;
  }
  .type {
    position: absolute;
    top: -80px;
    right: 10px;
    width: 493px;
    height: 115px;
    padding-top: 30px;
    padding-left: 50px;
    background: url(../img/partner/bg_type.png) 0 0 no-repeat;
    background-size: 493px auto;
    color: #fff;
    font-size: 26px;
    font-weight: bold;
    text-align: left;
    @include mq(sp) {
      @include fz_vw(12);
      top: -30px;
      right: 0;
      width: 200px;
      height: 55px;
      padding-top: 12px;
      padding-left: 16px;
      background-size: 200px auto;
    }
  }
  .comment {
    padding: 35px 60px;
    background: #E1EED2;
    @include mq(sp) {
      padding: 22px 20px 16px;
    }
  }
  .inner {
    @include clearfix();
    padding: 40px 55px;
    @include mq(sp) {
      padding: 20px 15px;
    }
  }
  .hosting-partner-left {
    float: left;
    width: 380px;
    @include mq(sp) {
      float: none;
      width: auto;
    }
  }
  .hosting-partner-right {
    float: right;
    width: 446px;
    padding-top: 10px;
    @include mq(sp) {
      float: none;
      width: auto;
    }
  }
  .hosting-partner-name {
    margin-bottom: 10px;
    font-size: 26px;
    font-weight: bold;
    @include mq(sp) {
      @include fz_vw(20);
      margin-bottom: 8px;
    }
  }
  .hosting-partner-outline {
    margin-bottom: 25px;
    font-size: 14px;
    @include mq(sp) {
      @include fz_vw(13);
    }
  }
  .hosting-partner-info {
    dt {
      padding: 5px 10px;
      background: #EFEFEF;
      font-size: 18px;
      font-weight: bold;
      @include mq(sp) {
        @include fz_vw(15);
      }
    }
    dd {
      padding: 6px 0 25px;
      font-size: 14px;
      @include mq(sp) {
        @include fz_vw(13);
      }
    }
    .operation-check {
      font-size: 0;
      img {
        width: 13.3%;
        height: 50px;
        margin-right: 1.15%;
        margin-bottom: 5px;
        &:nth-child(7) {
          margin-right: 0;
        }
      }
    }
  }
  .hosting-partner-image {
    margin-bottom: 50px;
    @include mq(sp) {
      margin-bottom: 30px;
    }
  }
  .hosting-partner-banner {
    width: 50%;
    margin: 0 auto 30px;
    @include mq(sp) {
      width: 70%
    }
  }
  .button--01 {
    margin-bottom: 70px;
    text-align: center;
    @include mq(sp) {
      margin-bottom: 35px;
    }
    a {
      width: 390px;
      height: 90px;
      border-radius: 45px;
      line-height: 88px;
      @include mq(sp) {
        width: 80vw;
        height: 50px;
        line-height: 47px;
      }
      &::before {
        border-radius: 45px;
      }
      span {
        padding-left: 20px;
        background: url(../img/common/icn_demo.svg) 40px center no-repeat;
        background-size: 20px 20px;
        @include mq(sp) {
          background-size: 12px 12px;
          background-position: 50px center;
        }
      }
    }
  }
  .hosting-partner-topics {
    clear: both;
    min-height: 117px;
    padding: 10px 15px;
    border: 1px solid #C9CACA;
    @include mq(sp) {
      padding: 10px;
    }
    .icn_topics {
      float: left;
      width: 93px;
      height: 93px;
      border-radius: 50%;
      background: #FAC559;
      color: #fff;
      text-align: center;
      line-height: 93px;
      @include mq(sp) {
        width: 70px;
        height: 70px;
        line-height: 70px;
      }
    }
    .text_topics {
      margin-left: 130px;
      padding-top: 23px;
      font-size: 14px;
      @include mq(sp) {
        @include fz_vw(13);
        margin-left: 90px;
        padding-top: 0;
      }
    }
  }
}

.hosting-list {
  margin-bottom: 40px;
  background: #F1F1F1;
  .inner {
    @include base-wrap--space();
    padding: 80px 0 0;
    @include mq(sp) {
      padding: 30px 0;
    }
  }
  ul {
    @include flex(wrap, flex-start);
    li {
      width: 32%;
      margin: 10px 5px;
      padding: 20px 2.5%;
      background: #fff;
      @include mq(sp) {
        width: auto;
        margin: 0 auto 20px;
        padding: 15px 2% 20px 2%;
        background: #fff;
      }
      .li-banner {
        margin-bottom: 20px;
        overflow: hidden;
        @include mq(sp) {
          text-align: center;
        }
        img {
          @include mq(sp) {
            width: 70%;
          }
        }
      }
      .li-text {
        position: relative;
        font-size: 13px;
        @include mq(sp) {
          @include fz_vw(13);
          padding: 0 3%;
        }
      }
      .li-name {
        margin-bottom: 10px;
        font-size: 18px;
        text-align: center;
        @include mq(sp) {
          @include fz_vw(15);
        }
      }
      .li-info {
        dt {
          padding: 5px 20px;
          background: #EFEFEF;
          font-size: 13px;
          font-weight: bold;
        }
        dd {
          padding: 5px 0 18px;
          font-size: 13px;
        }
        .operation-check {
          font-size: 0;
          img {
            width: 13.3%;
            height: 40px;
            margin-right: 1.15%;
            margin-bottom: 5px;
            &:nth-child(7) {
              margin-right: 0;
            }
          }
        }
      }
      a {
        position: relative;
        display: block;
        color: $black;
      }
    }
  }
}