.cate-01 {
  background-color: $pink;
  color: $pink;
  border-color: $pink;
  a {
    background-color: $pink;
    color: $pink;
    border-color: $pink;
  }
}
.cate-02 {
  background-color: $ocian;
  color: $ocian;
  border-color: $ocian;
  a {
    background-color: $ocian;
    color: $ocian;
    border-color: $ocian;
  }
}
.cate-03 {
  background-color: $orange;
  color: $orange;
  border-color: $orange;
  a {
    background-color: $orange;
    color: $orange;
    border-color: $orange;
  }
}
.cate-04 {
  background-color: #F39B4E;
  color: #F39B4E;
  border-color: #F39B4E;
  a {
    background-color: #F39B4E;
    color: #F39B4E;
    border-color: #F39B4E;
  }
}
.cate-05 {
  background-color: $main;
  color: $main;
  border-color: $main;
  a {
    background-color: $main;
    color: $main;
    border-color: $main;
  }
}
.cate-06 {
  background-color: $main;
  color: $main;
  border-color: $main;
  a {
    background-color: $main;
    color: $main;
    border-color: $main;
  }
}

.news {
  @include base-wrap--space();
  @include mq(sp) {
    padding: 25px 0 20px;
  }
  &__list {
    padding: 70px 0 50px;
    @include mq(sp) {
      padding: 35px 0 0;
    }
    ul {
      @include clearfix();
      li {
        float: left;
        width: 32%;
        margin-right: 2%;
        margin-bottom: 65px;
        @include mq(w1200){
          @include goldenRatiolize;
        };
        @include mq(sp) {
          margin-right: 10%;
          margin-bottom: 20%;
        }
        &:nth-child(3n-2) {
          @include mq(pc) {
            clear: both;
          }
        }
        &:nth-child(3n) {
          @include mq(pc) {
            margin-right: 0;
          }
        }
        &:nth-child(odd) {
          @include mq(sp) {
            clear: both;
          }
        }
        &:nth-child(even) {
          @include mq(sp) {
            margin-right: 0;
          }
        }
        .eyecatch {
          @include goldenRatiolize;
          position: relative;
          overflow: hidden;
          img {
            height: 100%;
            max-width: none;
            transition: .5s;
          }
          a {
            display: block;
            height: 100%;
            text-align: center;
            &:hover {
              img {
                transform: scale(1.1,1.1);
              }
            }
          }
        }
        .category {
          position: absolute;
          right: 0;
          bottom: 0;
          display: inline-block;
          min-width: 100px;
          padding: 6px 10px;
          color: #fff;
          font-size: 11px;
          text-align: center;
          vertical-align: middle;
          @include mq(sp) {
            @include fz_vw(10);
            padding: 0 8px;
          }
        }
        .meta {
          margin-bottom: 10px;
          .date {
            color: #6D6C6C;
            font-size: 14px;
            vertical-align: middle;
            @include mq(sp) {
              @include fz_vw(10);
            }
          }
        }
        .title {
          font-size: 14px;
          font-weight: normal;
          @include mq(sp) {
            @include fz_vw(10);
          }
          a {
            padding-bottom: 5px;
            border-bottom: 0 solid $gray;
            transition: .2s;
            &:hover {
              padding-bottom: 3px;
              border-width: 1px;
            }
          }
        }
      }
    }
  }
  &__detail {
    padding: 70px 0 50px;
    // pcサイズ≒1000pxを基準にvwを設定
    //width: 70vw;
    @include mq(sp) {
      width: 100%;
      padding: 35px 0 0;
    }
    
    .title {
      margin-bottom: 10px;
      font-size: 24px;
    }
    
    .meta {
      margin-bottom: 10px;
      
      .date {
        color: #6D6C6C;
        font-size: 14px;
        vertical-align: middle;
        @include mq(sp) {
          @include fz_vw(10);
        }
      }
      
      .category {
        display: inline-block;
        margin: 0 10px;
        vertical-align: middle;
        
        a {
          display: inline-block;
          min-width: 100px;
          padding: 5px 10px;
          color: #fff;
          font-size: 11px;
          text-align: center;
          @include mq(sp) {
            @include fz_vw(10);
            padding: 0 8px;
          }
        }
      }
    }
    .eyecatch {
      //height: calc(70vw / #{$golden-ratio});
      overflow: hidden;
      @include mq(sp) {
        height: calc(100vw / #{$golden-ratio});
      }
      img {
        max-width: 100vw;
        width:100%;
        //height: calc(70vw / #{$golden-ratio});
        @include mq(sp) {
          //width: 100vw;
          height: calc(100vw / #{$golden-ratio});
        }
      }
    }
    .bge_body {
      @include mq(wide) {
        width: 100%;
        margin: 0;
      }
    }
  }
}
.press-release {
  &__list {
    padding-top:40px;
    margin-bottom: 80px;
    li{
      border-bottom:1px solid #DDD;
      a {
        padding-top:40px;
        padding-bottom:40px;
        color:#000;
        display: block;
      }
      h2 {
        font-size: 20px;
        margin-bottom:5px;
      }
      .date {
        font-size:16px;
        color:#999;
        margin-bottom:5px;
      }
    }
    &__body {
      color:#666;
    }
  }
}
